import { ref } from "vue";
import { format } from "date-fns";
import { useMovimentiStore } from "~/store/movimentiStored";
import { useColliStore } from "~/store/colliStored";
import { useNodiStore } from "~/store/nodiStored";
import { useProdottiStoccabiliStore } from "~/store/prodottiStoccabiliStored";
import { useUsciteConsegnaStore } from "~/store/usciteConsegnaStored";
import { useSquadreStore } from "~/store/squadreStored";
import { useOrdiniAcquistoStore } from "~/store/ordiniAcquistoStored";
// import { useTicketAssistenzaStore } from "~/store/ticketAssistenzaStored";
import { useAuthStored } from "~/store/authStored";
import { useOrdiniVenditaStore } from "~/store/ordiniVenditaStored";
import { useDatiAusiliariDDT } from "~/store/datiAusiliariDDTStored";
export const useGetNecessaryData = () => {
  const movimentiStore = useMovimentiStore();
  const colliStore = useColliStore();
  const nodiStore = useNodiStore();
  const stoccabiliStore = useProdottiStoccabiliStore();
  const usciteConsegnaStore = useUsciteConsegnaStore();
  const squadreStore = useSquadreStore();
  const ordiniAcquistoStore = useOrdiniAcquistoStore();
  // const ticketAssistenzaStore = useTicketAssistenzaStore();
  const authStored = useAuthStored();
  const ordiniVenditaStore = useOrdiniVenditaStore();
  const datiAusiliariDDT = useDatiAusiliariDDT();

  const getAllData = () => {
    if (colliStore.colli.length === 0 && !colliStore.caricandoColli) {
      colliStore.getColliOdoo();
    }

    if (
      movimentiStore.movimenti.length === 0 &&
      !movimentiStore.caricandoMovimenti
    ) {
      movimentiStore.getMovimentiOdoo();
    }

    if (nodiStore.nodi.length === 0 && !nodiStore.caricandoNodi) {
      nodiStore.getNodiOdoo();
    }

    if (nodiStore.nodiLogstiche.length === 0 && !nodiStore.caricandoNodi) {
      nodiStore.getNodiLogsticheOdoo();
    }

    if (
      stoccabiliStore.prodottiStoccabili.length === 0 &&
      !stoccabiliStore.caricandoProdotti
    ) {
      stoccabiliStore.getStoccabiliOdoo();
    }

    if (!datiAusiliariDDT.isAllDataFilled) {
      datiAusiliariDDT.getDatiAusiliariDDTOdoo();
    }

    if (
      usciteConsegnaStore.usciteConsegna.length === 0 &&
      authStored.ruoli.includes("logistica") &&
      !usciteConsegnaStore.caricandoUsciteConsegna
    ) {
      usciteConsegnaStore.getUsciteConsegnaOdoo();
    }

    if (
      squadreStore.squadre.length === 0 &&
      authStored.ruoli.includes("logistica") &&
      !squadreStore.caricandoSquadre
    ) {
      squadreStore.getSquadreOdoo();
    }

    if (
      ordiniAcquistoStore.ordiniAcquisto.length < 20 &&
      authStored.ruoli.includes("fornitore") &&
      !ordiniAcquistoStore.caricandoOrdiniAcquisto
    ) {
      ordiniAcquistoStore.getOrdiniAcquistoOdoo();
    }

    // if (
    //   ticketAssistenzaStore.ticketAssistenze.length === 0 &&
    //   authStored.ruoli.includes("logistica")
    // ) {
    //   ticketAssistenzaStore.getTicketAssistenzaOdoo();
    // }

    if (
      ordiniVenditaStore.ordiniVendita.length === 0 &&
      authStored.ruoli.includes("logistica") &&
      !ordiniVenditaStore.caricandoOrdiniVendita
    ) {
      ordiniVenditaStore.getOrdiniVenditaOdoo();
    }
  };

  return {
    getAllData
  };
};
