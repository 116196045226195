import { defineStore } from "pinia";
import { useGetData } from "~/composables/useGetData";
import { usePostData } from "~/composables/usePostData";
import { usePostDataFileStream } from "~/composables/usePostDataFileStream";

export const useOrdiniAcquistoStore = defineStore("ordiniAcquistoStore", {
  state: () => ({
    ordiniAcquisto: [],
    ordiniAcquistoSelezionato: {},
    caricandoOrdiniAcquisto: false,
    errorMessage: null,
    fromDate: null,
    toDate: null,
    filtroCompanyId: null,
    filtraSpedito: null,
    filtri: null
  }),
  getters: {
    isOrdiniAcquistoSelezionato (state) {
      if (
        !state.ordiniAcquistoSelezionato ||
        Object.keys(state.ordiniAcquistoSelezionato).length === 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  actions: {
    async getOrdiniAcquistoOdoo () {
      this.caricandoOrdiniAcquisto = true;
      const { data, getData, loading, messageError } = useGetData();
      await getData("/api/ordini_acquisto");
      if (!messageError.value) {
        if (this.ordiniAcquisto.length === 0) {
          this.ordiniAcquisto = data.value;
        } else {
          for (const ordine of data.value) {
            this.aggiornareElemento(ordine);
          }
        }

        this.caricandoOrdiniAcquisto = loading.value;
      } else {
        this.errorMessage = messageError;
        this.caricandoOrdiniAcquisto = false;
      }
    },
    async getOrdiniAcquistoDaPrendereCaricoOdoo () {
      this.caricandoOrdiniAcquisto = true;
      const { data, getData, loading, messageError } = useGetData();
      await getData("/api/ordini_acquisto/prendere_in_carico");
      if (!messageError.value) {
        if (this.ordiniAcquisto.length === 0) {
          this.ordiniAcquisto = data.value;
        } else {
          for (const ordine of data.value) {
            this.aggiornareElemento(ordine);
          }
        }
        this.caricandoOrdiniAcquisto = false;
      } else {
        this.errorMessage = messageError;
        this.caricandoOrdiniAcquisto = false;
      }
    },
    async aggiornaStato (ordine) {
      // aggiorna lo stato dell'ordine acquisto. dentro il body dell'ordine c'è inserito che stato sta aggiornando
      // esempio: { id: item.id, action_url: "cambia_in_carico"}
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/ordini_acquisto/aggiorna_stato", ordine);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.ordini);
        this.caricandoOrdiniAcquisto = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    aggiornareElemento (data) {
      const index = this.ordiniAcquisto.findIndex(({ id }) => id === data.id);
      Object.assign(this.ordiniAcquisto[index], data);
    },
    resetOrdiniAcquistoSelezionato () {
      this.ordiniAcquistoSelezionato = [];
    },
    async stampaOrdiniAcquisto (body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/ordini_acquisto/stampa_ordini_acquisto", body);
      return data.value;
    },
    async excelOrdiniAcquisto (body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/ordini_acquisto/excel_ordini_acquisto", body);
      return data.value;
    },
    async searchOrdiniAcquistoFiltrati (filtri: Array<Object>) {
      this.caricandoOrdiniAcquisto = true;
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/ordini_acquisto/cerca_ordini_acquisto", filtri);
      if (!messageError.value) {
        this.ordiniAcquisto = data.value.result;
        this.caricandoOrdiniAcquisto = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    }
  }
});
